<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card-code>
          <b-tabs>
            <b-tab active title="Show All">
              <div style="max-height: 700px; overflow-y: auto">
                <b-card title="Activity Log">
                  <app-timeline>
                    <!-- 12 INVOICES HAVE BEEN PAID -->
                    <app-timeline-item
                      v-for="(log, index) in boardActivityLog"
                      :key="index"
                      :variant="setLogVariant(log)"
                    >
                      <div
                        class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                      >
                        <h6 v-html="formatLogTitle(log)"></h6>
                        <small class="text-muted">{{
                          formatDateTime(log?.created_at)
                        }}</small>
                      </div>
                      <p v-html="formatLogDescription(log)"></p>
                    </app-timeline-item>
                  </app-timeline>
                </b-card>
              </div>
            </b-tab>
          </b-tabs>
        </b-card-code>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BAlert,
  VBTooltip,
  BFormDatepicker,
  BTabs,
  BTab,
  BImg,
  BMedia,
  BAvatarGroup,
  BEmbed,
  BDropdownDivider,
  BButtonGroup,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import { VueGoodTable } from "vue-good-table";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, size, ext, max_value, min_value } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { kFormatter } from "@core/utils/filter";
import { priorityOptionConstants } from "@/helpers/constant/priorityOptionConstant";

export default {
  name: "TaskDetailsView",
  components: {
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BAlert,
    BFormDatepicker,
    BTabs,
    BTab,
    BImg,
    BMedia,
    AppTimeline,
    AppTimelineItem,
    BAvatarGroup,
    BEmbed,
    BDropdownDivider,
    quillEditor,
    BCardCode,
    BButtonGroup,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  methods: {
    kFormatter,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      //activity log
      boardActivityLog: [],
      boardAllTeamMembers: [],
      priorityOptionConstants,
      taskCreatedUser: "",
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    this.loadItems();
  },

  methods: {
    async getBoardActivityLog() {
      return await this.$api.get(
        `api/milestone-task-boards/${this.$route.params.id}/activitylog`
      );
    },
    async getBoardTeamMembers() {
      return await this.$api.get(
        `api/milestone-task-boards/${this.$route.params.id}?include=team`
      );
    },
    async getBoardTeamMembers() {
      return await this.$api.get(
        `api/milestone-task-boards/${this.$route.params.id}?include=team`
      );
    },

    async loadItems() {
      // board activity_log/history
      try {
        const [boardActivityLogs, boardTeamMembers] = await Promise.all([
          this.getBoardActivityLog(),
          this.getBoardTeamMembers(),
        ]);

        this.boardActivityLog = boardActivityLogs?.data?.data;
        this.boardAllTeamMembers = boardTeamMembers?.data?.data?.team?.data;

        const superVisorInfo = await this.getIdWiseUser(
          boardTeamMembers?.data?.data?.supervisor_id
        );
        const supervisorData = superVisorInfo?.data;

        const mergedArray = [
          ...this.boardAllTeamMembers,
          ...(Array.isArray(supervisorData)
            ? supervisorData
            : [supervisorData]),
        ];

        this.boardAllTeamMembers = mergedArray;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    formatLogDescription(value) {
      let description = "";
      if (value?.event != "created") {
        if (value?.event == "updated") {
          //showing task update (Phase and Priority) activity log
          if (value?.log_name == "tasks") {
            if (
              value?.properties?.old?.phase_id !=
              value?.properties?.attributes?.phase_id
            ) {
              description = `Phase updated from <span class="font-weight-bolder text-warning">${value?.properties?.old?.phase?.title} </span> to  <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.phase?.title} </span> <br>`;
            }

            if (
              value?.properties?.old?.priority !=
              value?.properties?.attributes?.priority
            ) {
              const oldPriorityName = this.priorityOptionConstants.reduce(
                (acc, option) => {
                  if (option.value === value?.properties?.old?.priority) {
                    return option.name;
                  }
                  return acc;
                }
              );

              const newPriorityName = this.priorityOptionConstants.reduce(
                (acc, option) => {
                  if (
                    option.value === value?.properties?.attributes?.priority
                  ) {
                    return option.name;
                  }
                  return acc;
                }
              );

              description =
                description +
                `Priority updated from <span class="font-weight-bolder text-warning">${oldPriorityName} </span> to  <span class="font-weight-bolder text-warning">${newPriorityName} </span>`;
            }
          }
          if (value?.log_name == "sub_tasks") {
            if (
              value?.properties?.old?.phase_id !=
              value?.properties?.attributes?.phase_id
            ) {
              description = `Phase updated from <span class="font-weight-bolder text-warning">${value?.properties?.old?.phase?.title} </span> to  <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.phase?.title} </span> <br>`;
            }
          }

          if (value?.log_name == "comments") {
            description = "[Previous]";
            description = description + value?.properties?.old?.description;

            description = description + "[Updated]";
            description =
              description + value?.properties?.attributes?.description;
          }
        }

        if (value?.event == "deleted") {
          if (value?.log_name == "comments") {
            description = "[Deleted]";
            description = description + value?.properties?.old?.description;
          }
        }

        if (value?.log_name === "default") {
          if (value?.description == "Task Team Assign Activity Logged") {
            let addedMembers = value?.properties?.added_members;

            if (addedMembers.length > 0) {
              for (let i = 0; i < addedMembers.length - 1; i++) {
                description =
                  description +
                  `<span class="font-weight-bolder text-warning">${addedMembers[i].name} (${addedMembers[i]?.email})</span>, `;
              }

              description =
                description +
                `<span class="font-weight-bolder text-warning">${
                  addedMembers[addedMembers.length - 1].name
                } (${
                  addedMembers[addedMembers.length - 1]?.email
                })</span> were assigned to the Task.<br><br>`;
            }

            let removedMembers = value?.properties?.removed_members;

            if (removedMembers.length > 0) {
              for (let i = 0; i < removedMembers.length - 1; i++) {
                description =
                  description +
                  `<span class="font-weight-bolder text-danger">${removedMembers[i].name} (${removedMembers[i]?.email})</span>, `;
              }

              description =
                description +
                `<span class="font-weight-bolder text-danger">${
                  removedMembers[removedMembers.length - 1].name
                } (${
                  removedMembers[removedMembers.length - 1]?.email
                })</span> were removed from the Task.`;
            }
          }
        }

        if (value?.log_name === "default") {
          if (value?.description == "Sub Task Team Assign Activity Logged") {
            let addedMembers = value?.properties?.added_members;

            if (addedMembers.length > 0) {
              for (let i = 0; i < addedMembers.length - 1; i++) {
                description =
                  description +
                  `<span class="font-weight-bolder text-warning">${addedMembers[i].name} (${addedMembers[i]?.email})</span>, `;
              }

              description =
                description +
                `<span class="font-weight-bolder text-warning">${
                  addedMembers[addedMembers.length - 1].name
                } (${
                  addedMembers[addedMembers.length - 1]?.email
                })</span> were assigned to the Sub Task.<br><br>`;
            }

            let removedMembers = value?.properties?.removed_members;

            if (removedMembers.length > 0) {
              for (let i = 0; i < removedMembers.length - 1; i++) {
                description =
                  description +
                  `<span class="font-weight-bolder text-danger">${removedMembers[i].name} (${removedMembers[i]?.email})</span>, `;
              }

              description =
                description +
                `<span class="font-weight-bolder text-danger">${
                  removedMembers[removedMembers.length - 1].name
                } (${
                  removedMembers[removedMembers.length - 1]?.email
                })</span> were removed from it.`;
            }
          }
        }
      }

      if (value?.event == "created") {
        if (value?.log_name == "comments") {
          description = description + "[New Comment]";
          description =
            description + value?.properties?.attributes?.description;
        }
      }

      return description;
    },
    formatLogTitle(value) {
      let logTitle = "";

      if (value?.event === "created") {
        if (value?.log_name == "phases") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.attributes?.created_by;
          });
          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");

          logTitle = `In this board phase  <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.title} </span> was created by ${createdUserNames} `;
        }

        if (value?.log_name == "tasks") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.attributes?.created_by;
          });
          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");

          logTitle = `Task  <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.title} </span> was created by ${createdUserNames}`;
        }

        if (value?.log_name == "sub_tasks") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.attributes?.created_by;
          });
          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");

          logTitle = `In this Task  <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.title} </span> was added as a Sub Task by ${createdUserNames}`;
        }

        if (value?.log_name == "linked_tasks") {
          if (value?.properties?.attributes?.link_from == "task") {
            logTitle = `Task  <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.task_subtask_title} </span> was linked with <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.linkable?.title} </span> Task `;
          } else {
            logTitle = `SubTask  <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.task_subtask_title} </span> was linked with <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.linkable?.title} </span> SubTask `;
          }
        }

        if (value?.log_name == "comments") {
          if (value?.properties?.attributes?.comment_from == "task") {
            logTitle = ` <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.createdBy?.name} (${value?.properties?.attributes?.createdBy?.email}) </span> commented on <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.commentable?.title} </span> Task `;
          } else {
            logTitle = ` <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.createdBy?.name} (${value?.properties?.attributes?.createdBy?.email}) </span> commented on <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.commentable?.title} </span> Sub Task `;
          }
        }
      } else if (value?.event === "updated") {
        if (value?.log_name == "phases") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.attributes?.created_by;
          });
          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");
          logTitle = `Phase  <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.title}'s </span> information updated by ${createdUserNames}`;
        }

        if (value?.log_name == "tasks") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.attributes?.created_by;
          });
          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");
          logTitle = `Task  <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.title}'s </span> information updated by ${createdUserNames}`;
        }

        if (value?.log_name == "sub_tasks") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.attributes?.created_by;
          });
          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");
          logTitle = `Sub Task  <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.title}'s </span> information updated by ${createdUserNames}`;
        }

        if (value?.log_name == "comments") {
          logTitle = ` <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.createdBy?.name} (${value?.properties?.attributes?.createdBy?.email}) </span> updated his previous comment on this Task `;
        }
      } else if (value?.event === "deleted") {
        if (value?.log_name == "tasks") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.old?.deleted_by;
          });

          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");
          logTitle = `Task  <span class="font-weight-bolder text-danger">${value?.properties?.old?.title} </span> was moved to Trash by ${createdUserNames}`;
        }

        if (value?.log_name == "sub_tasks") {
          // Show name
          const filteredMembers = this.boardAllTeamMembers.filter((member) => {
            return member.id === value?.properties?.attributes?.created_by;
          });
          const createdUserNames = filteredMembers
            .map((member) => member.name)
            .join(", ");
          logTitle = `Sub Task  <span class="font-weight-bolder text-danger">${value?.properties?.old?.title} </span> was moved to Trash by ${createdUserNames}`;
        }

        if (value?.log_name == "linked_tasks") {
          if (value?.properties?.old?.link_from == "task") {
            logTitle = `Task <span class="font-weight-bolder text-danger">${value?.properties?.old?.task_subtask_title} </span> was unlinked from <span class="font-weight-bolder text-primary">${value?.properties?.old?.linkable?.title}</span> Task `;
          } else {
            logTitle = `Sub Task  <span class="font-weight-bolder text-danger">${value?.properties?.old?.task_subtask_title} </span> was unlinked from <span class="font-weight-bolder text-primary">${value?.properties?.old?.linkable?.title}</span> Sub Task `;
          }
        }

        if (value?.log_name == "comments") {
          logTitle = ` <span class="font-weight-bolder text-danger">${value?.properties?.old?.createdBy?.name} (${value?.properties?.old?.createdBy?.email}) </span> deleted his comment from this Task `;
        }
      }

      if (value?.log_name === "default") {
        if (value?.description == "Task Team Assign Activity Logged") {
          logTitle = `Task <span class="font-weight-bolder text-warning"> Assigned Member </span> was updated for <span class="font-weight-bolder text-primary"> ${value?.properties?.task?.title} </span> Task`;
        }

        if (value?.description == "Sub Task Team Assign Activity Logged") {
          logTitle = `Sub Task <span class="font-weight-bolder text-warning"> Assigned Member </span> was updated for <span class="font-weight-bolder text-primary"> ${value?.properties?.subtask?.title} </span> Sub Task`;
        }
      }

      return logTitle;
    },
    async getIdWiseUser(value) {
      const userId = value;
      const response = await this.$api.get(`api/users/${userId}`);
      return response.data;
    },
    async taskCreatedUserName(value) {
      const userInfo = await this.getIdWiseUser(value);
      this.taskCreatedUser = userInfo?.data?.name;
    },
    setLogVariant(value) {
      const logColors = {
        created: "primary",
        updated: "warning",
        deleted: "danger",
      };
      return logColors[value?.event] || "warning";
    },
    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";

.custom-font {
  font-size: 11px !important;
}

.label-margin {
  margin-top: 8px;
  margin-right: 8px;
}

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.custom-font {
  font-size: 13px !important;
}

.file-input {
  display: inline-block;
  position: relative;
}

.attach-icon {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.uploaded-files {
  margin-top: 20px;
}

.file-preview {
  display: inline-block;
  margin-right: 10px;
}
.file-preview .bigger-icon {
  font-size: 50px;
  color: #999;
}
.button-padding {
  padding-bottom: 15px;
}
</style>
