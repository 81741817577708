var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-code', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Show All"
    }
  }, [_c('div', {
    staticStyle: {
      "max-height": "700px",
      "overflow-y": "auto"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Activity Log"
    }
  }, [_c('app-timeline', _vm._l(_vm.boardActivityLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": _vm.setLogVariant(log)
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogTitle(log))
      }
    }), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))])]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogDescription(log))
      }
    })]);
  }), 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }